import DialogBox from 'components/DialogBox/DialogBox.react';
import PropTypes from 'prop-types';
import React, { useContext, useState, useMemo, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  MODAL_CONTENT,
  acceptedFilesInfo,
  maxFilesError,
  getError,
  inputStyleProps,
  totalAcresInputProps,
  dropdownStyleProps,
  calculateActivityErrors,
  STATUS,
} from './AddContractModal.content';
import {
  DateSectionWrapper,
  DragAndDropText,
  DragAndDropTitle,
  FieldSectionWrapper,
  FileName,
  SmallUploadWrapper,
  Subtext,
  UploadHelpText,
  AcceptedFileItem,
  DropBoxStyle,
  DialogBoxButtonStyle,
  ErrorWrapper,
  InstructionText,
  TabSx,
  dialogTitleSx,
  tabHeadingSx,
  TabsStyle,
} from './AddContractModal.style';
import FieldValueComponent from 'components/FormComponents/FieldValueComponent/FieldValueComponent.react';
import {
  COOL_GRAY,
  DARK_CHARCOAL,
  QUILL_GRAY,
  TABS_HIGHLIGHT_BORDER,
} from 'theme/GlobalColors';
import {
  b64toBlob,
  checkTernaryCondition,
  convertDateFormat,
  scroll,
  isEmpty,
} from 'utils/helper';
import { deleteIconSx } from 'components/FormComponents/FormStyles';
import axios from 'axios';
import { ADD_CONTRACT, FETCH_PROJECT_CYCLES, GET_CONTRACT_DETAILS } from 'urls';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { toast } from 'react-toastify';
import { toastStyle } from 'pages/AddOriginationProject/AddOriginationProject.style';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import uuid from 'react-uuid';
import { LifeCycleContext } from 'contextAPI/statusLifecycleContext';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import Loader from 'components/Loader/Loader.react';
import ProjectActivityDetailsSections from './ProjectActivityDetailsSections';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useStyles } from 'theme/GlobalStyles';
import classNames from 'classnames';
import TabPanel from 'components/Tabber/TabPanel.react';
import { ReactComponent as AlertIcon } from 'assets/icons/AlertIcon.svg';
import { uomContext } from 'contextAPI/unitOfMeasureContext';
import { ReactComponent as GreyIcon2 } from '../../../../../assets/icons/GreyIcon2.svg';
import { ReactComponent as GreenIcon } from '../../../../../assets/icons/greenStatus.svg';

const AddContractModal = ({
  addContractOpen,
  setAddContractOpen,
  refreshHandler,
  activityPracticeMapping,
  projectId,
  participantId,
  interestedCycleId,
  contractId,
}) => {
  const {
    unitOfMeasureList: { contracting },
  } = useContext(uomContext);
  const { addContract } = useContext(LifeCycleContext);
  const [uploadError, setUploadError] = useState({ flag: false, message: '' });
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [projectCycles, setProjectCycles] = useState([]);
  const [endProjectCycleId, setEndProjectCycleId] = useState(null);
  const [projectEndCycleError, setProjectEndCycleError] = useState('');
  const [loading, setLoading] = useState(false);
  const [projectCycleYears, setProjectCycleYears] = useState([
    {
      year: '',
      projectCycleId: '',
    },
  ]);
  const [tabData, setTabData] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const { TabberBox } = useStyles();
  const [totalAcresPerYear, setTotalAcresPerYear] = useState([
    {
      projectCycleId: interestedCycleId,
      totalContractedAcres: '',
      totalContractedAcresError: '',
    },
  ]);
  const [activityDataInfo, setActivityDataInfo] = useState([
    {
      projectCycleId: interestedCycleId,
      activityDetails: activityPracticeMapping,
      incentiveErrorListUnique: [],
    },
  ]);
  const [acreExceedError, setAcreExceedError] = useState(false);

  /**
   * State variable to set the status per project cycle ID (i.e., per year).
   * This state has a key which corresponds to the year and a value of either STATUS.IN_PROGRESS or STATUS.COMPLETED.
   *
   * @type {Object.<number, string>}
   */
  const [statusPerYear, setStatusPerYear] = useState({});
  const handleTabChange = (_, selectedTab) => {
    setCurrentTab(selectedTab);
  };
  const tabProps = (index) => {
    return {
      id: `tab-${index}`,
    };
  };
  /**
   * Checks the status of an activity based on its incentive rate and total contracted acres.
   *
   * @param {Object} activity - The activity object, which contains details per activity category.
   * @param {Array} totalAcresYearWise - The array of objects representing total acres per year.
   * @param {number} projectCycleId - The ID of the current project cycle tab.
   * @returns {boolean} - Returns true if the activity's incentive rate is empty or less than 1,
   *                      or if the total contracted acres for the given project cycle ID is empty.
   */
  const getStatusUpdate = (activity, totalAcresYearWise, projectCycleId) => {
    return (
      isEmpty(activity.incentiveRate) ||
      isEmpty(
        totalAcresYearWise.find(
          (item) => item.projectCycleId === projectCycleId,
        )?.totalContractedAcres,
      ) ||
      +activity.incentiveRate < 1
    );
  };

  /**
   * Checks if any activity within the activity details has an incomplete status.
   *
   * @param {Object} activityDetails - An object containing arrays of activities per cycle id.
   * @param {Array} totalAcresYearWise - An object representing total acres per year.
   * @param {number} projectCycleId - The ID of the project cycle.
   * @returns {boolean} - Returns true if any activity has an incomplete status, otherwise false.
   */
  const getIncentiveIncompleteStatus = (
    activityDetails,
    totalAcresYearWise,
    projectCycleId,
  ) => {
    return Object.values(activityDetails).some((activities) => {
      // Object.values fetches all values in object which are the arrays we want to check
      if (Array.isArray(activities)) {
        return activities.some((activity) => {
          // Array.some tests whether at least one element in the array passes the test implemented by the provided function
          return getStatusUpdate(activity, totalAcresYearWise, projectCycleId);
        });
      }
      return false;
    });
  };

  /**
   *
   * @returns true if the selected cycle(end date) is less than the interested cycle(start date)
   */
  const hasProjectCycleError = (selectedCycleId) => {
    const currentCycleYear = projectCycles.find(
      (cycle) => cycle.value === selectedCycleId,
    )?.cycleYear;
    const interestedCycleYear = projectCycles.find(
      (cycle) => cycle.value === interestedCycleId,
    )?.cycleYear;

    return new Date(currentCycleYear) < new Date(interestedCycleYear);
  };

  const updateActivityDetailsFromGetApiRawData = (rawData) => {
    return rawData.projectActivityResponseDtoList.map((item) => {
      const activityDetails = {};
      item.contractIncentiveResponseDtoList.forEach((activity) => {
        const activityWithData = {
          projectActivityId: activity.projectActivityId,
          activityCategory: activity.activityCategory,
          projectActivityType: activity.projectActivityType,
          contractedUnits: activity.contractedUnits,
          incentiveRate: activity.incentiveRate,
          contractActivityIncentiveId: activity.contractActivityIncentiveId,
          error: null,
        };
        if (activityDetails[activity.activityCategory]) {
          activityDetails[activity.activityCategory].push(activityWithData);
        } else {
          activityDetails[activity.activityCategory] = [activityWithData];
        }
      });
      return {
        projectCycleId: item.projectCycleId,
        activityDetails: activityDetails,
        incentiveErrorListUnique: [],
      };
    });
  };

  const clearUploadFileDetailsOnClose = () => {
    setUploadedFiles([]);
    setRejectedFiles([]);
    setUploadError({ flag: false, message: '' });
  };
  /**
   * Updates the status for all years from the activity map.
   *
   * @param {Object[]} activityData - The activity data information at various stages.
   * @param {Object[]} totalAcresObject - The total acres object.
   * @param {Object[]} projectCycleYearsObject - The project cycle years object which contains the year and project cycle ID.
   * @returns {Object} The updated object for status per year which can be set to the state variable.
   */
  const updateStatusForAllYearsFromTheActivityMap = (
    activityData,
    totalAcresObject,
    projectCycleYearsObject,
  ) => {
    let statusPerYearUpdated = {};
    // use the state update logic here too for the statusPerYear but now for all the years
    activityData.forEach((activity) => {
      let newStatus = {};
      const projectCycleId = activity.projectCycleId;
      const activityDetails = activity.activityDetails;
      if (!isEmpty(activityDetails)) {
        const incentiveIncompleteStatus = getIncentiveIncompleteStatus(
          activityDetails,
          totalAcresObject,
          projectCycleId,
        );
        const { hasError } = calculateActivityErrors(
          activityDetails,
          totalAcresObject.find(
            (item) => item.projectCycleId === projectCycleId,
          )?.totalContractedAcres,
        );
        const status = checkTernaryCondition(
          incentiveIncompleteStatus || hasError,
          STATUS.IN_PROGRESS,
          STATUS.COMPLETED,
        );
        const currentYear = projectCycleYearsObject.find(
          (item) => item.projectCycleId === projectCycleId,
        )?.year;
        newStatus = {
          [currentYear]: status,
        };
      }
      statusPerYearUpdated = {
        ...statusPerYearUpdated,
        ...newStatus,
      };
    });

    return statusPerYearUpdated;
  };

  const fetchContractDetails = async () => {
    axios
      .get(`${GET_CONTRACT_DETAILS}?contractId=${contractId}`)
      .then((response) => {
        const rawData = response.data;
        // set the start and end date
        const projectCycleStartYear = +rawData.projectCycleStartDate.substring(
          0,
          4,
        );
        const projectCycleEndYear = +rawData.projectCycleEndDate.substring(
          0,
          4,
        );
        const projectCycleEndId = projectCycles.find(
          (cycle) => cycle.cycleYear === projectCycleEndYear,
        )?.value;
        let years = [];
        /* istanbul ignore else*/
        if (projectCycleStartYear && projectCycleEndYear) {
          for (let i = projectCycleStartYear; i <= projectCycleEndYear; i++) {
            //get the projectCycleId from the year
            const projectCycleId = projectCycles.find(
              (cycle) => cycle.cycleYear === i,
            )?.value;
            /* istanbul ignore else*/
            if (projectCycleId)
              years.push({ year: i, projectCycleId: projectCycleId });
          }
        }
        setProjectCycleYears(years);
        setEndProjectCycleId(projectCycleEndId);
        // PDF HANDLING
        /* istanbul ignore else*/
        if (
          !isEmpty(rawData.signedPdfUrl) &&
          !isEmpty(rawData.pdfFileName) &&
          !isEmpty(rawData.pdfBlob)
        ) {
          const pdfContent = rawData.pdfBlob;
          const blob = b64toBlob(pdfContent, 'application/pdf');
          const file = new File([blob], `${rawData.pdfFileName}`, {
            type: 'application/pdf',
          });
          setUploadedFiles([
            {
              file: file,
              id: uuid(),
            },
          ]);
        }
        // to update the totalAcresPerYear loop on each object under projectActivityResponseDtoList get the contractProjectCycleId,projectCycleId and totalContractedAcres also add a new key totalContractedAcresError and set it to '' and take all these into object and inside one array
        const totalAcresPerYearUpdated =
          rawData.projectActivityResponseDtoList.map((item) => {
            return {
              projectCycleId: item.projectCycleId,
              totalContractedAcres: item.totalContractedAcres,
              contractProjectCycleId: item.contractProjectCycleId,
              totalContractedAcresError: '',
            };
          });
        const activityDataInfoUpdated =
          updateActivityDetailsFromGetApiRawData(rawData);

        // use the state update logic here too for the statusPerYear but now for all the years
        const statusPerYearUpdated = updateStatusForAllYearsFromTheActivityMap(
          activityDataInfoUpdated,
          totalAcresPerYearUpdated,
          years,
        );

        setStatusPerYear(statusPerYearUpdated);
        setActivityDataInfo(activityDataInfoUpdated);
        setTotalAcresPerYear(totalAcresPerYearUpdated);
      })
      .catch(() => {
        /* do api error handling */
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // need to get the values for activityDataInfo and totalAcresPerYear from the get api cal when the modal is opened in edit mode and populate data accordingly

  useEffect(() => {
    if (addContractOpen.editMode) {
      // reconstruct activityDataInfo and totalContractedAcresPerYear from the api response
      if (contractId && !isEmpty(projectCycles)) {
        setLoading(true);
        fetchContractDetails();
      }
    }
  }, [addContractOpen, contractId, projectCycles]);

  useEffect(() => {
    const incentiveRateErrorArr = Object.keys(activityPracticeMapping)
      .filter((category) => category !== 'totalAcres')
      .flatMap((activityCategory) => {
        return activityPracticeMapping[activityCategory].map((practice) => {
          return { projectActivityId: practice.projectActivityId, error: '' };
        });
      });
    setActivityDataInfo([
      {
        projectCycleId: interestedCycleId,
        activityDetails: activityPracticeMapping,
        incentiveErrorListUnique: incentiveRateErrorArr,
      },
    ]);
  }, [activityPracticeMapping]);

  useEffect(() => {
    /* istanbul ignore else*/
    if (projectCycleYears) {
      //loop over ProjectCycleYears and create tabData with title as the year and content as the ProjectActivityDetailsSections
      let tabularData = [];
      projectCycleYears.map((cycleInfo) => {
        const currentCycleYearLabel =
          projectCycles?.find((cycle) => cycle.cycleYear === cycleInfo.year)
            ?.label ?? '';
        return tabularData.push({
          title: cycleInfo.year,
          content: (
            <ProjectActivityDetailsSections
              contextVal={contracting}
              projectCycleId={cycleInfo.projectCycleId}
              acreExceedError={acreExceedError}
              setAcreExceedError={setAcreExceedError}
              key={cycleInfo.projectCycleId}
              year={cycleInfo.year}
              totalEnrolledAcres={
                totalAcresPerYear.find(
                  (item) => item.projectCycleId === cycleInfo.projectCycleId,
                )?.totalContractedAcres
              }
              handleEnrolledAcresUpdate={(event) =>
                handleEnrolledAcresUpdate(
                  event,
                  cycleInfo.year,
                  cycleInfo.projectCycleId,
                )
              }
              enrolledAcresError={
                totalAcresPerYear.find(
                  (item) => item.projectCycleId === cycleInfo.projectCycleId,
                )?.totalContractedAcresError
              }
              totalAcresInputProps={{
                ...totalAcresInputProps(
                  contracting.find(
                    (item) => item.TOTAL_CONTRACTED_AREA !== undefined,
                  )?.TOTAL_CONTRACTED_AREA[0].uomNameDisplay,
                ),
                label: `${MODAL_CONTENT.total_enrolled_acres_label}${currentCycleYearLabel}.`,
                type: 'number',
              }}
              activityPracticeData={
                activityDataInfo.find(
                  (item) => item.projectCycleId === cycleInfo.projectCycleId,
                )?.activityDetails ?? activityPracticeMapping
              }
              handlePracticeDataUpdate={handlePracticeDataUpdate}
              inputStyleProps={inputStyleProps}
              uniqueIncentiveErrorList={
                // get the incentiveErrorListUnique for a particular projectCycle id but get only the error message and only the unique ones
                Array.from(
                  new Set(
                    activityDataInfo
                      .find(
                        (item) =>
                          item.projectCycleId === cycleInfo.projectCycleId,
                      )
                      ?.incentiveErrorListUnique.map((item) => item.error),
                  ),
                )
              }
            />
          ),
        });
      });
      if (tabularData.length - 1 < currentTab) {
        setCurrentTab(0);
      }
      setTabData(tabularData);
    }
  }, [projectCycleYears, activityDataInfo, totalAcresPerYear, projectCycles]);

  useEffect(() => {
    const incentiveRateErrorArr = Object.keys(activityPracticeMapping).flatMap(
      (activityCategory) => {
        if (Array.isArray(activityPracticeMapping[activityCategory])) {
          return activityPracticeMapping[activityCategory].map((practice) => {
            return { projectActivityId: practice.projectActivityId, error: '' };
          });
        } else {
          return [];
        }
      },
    );
    setActivityDataInfo([
      {
        projectCycleId: interestedCycleId,
        activityDetails: activityPracticeMapping,
        incentiveErrorListUnique: incentiveRateErrorArr,
      },
    ]);
  }, [activityPracticeMapping]);

  const returnInitialErrorList = () => {
    return Object.keys(activityPracticeMapping)
      .filter((category) => category !== 'totalAcres')
      .flatMap((activityCategory) => {
        return activityPracticeMapping[activityCategory].map((practice) => {
          return {
            projectActivityId: practice.projectActivityId,
            error: '',
          };
        });
      });
  };

  const dropZoneProps = {
    ...(acceptedFilesInfo.mimeType &&
      acceptedFilesInfo.extensions && {
        accept: { [acceptedFilesInfo.mimeType]: acceptedFilesInfo.extensions },
      }),
    ...(acceptedFilesInfo.maxFiles && { maxFiles: acceptedFilesInfo.maxFiles }),
    ...(acceptedFilesInfo.maxFileSize && {
      maxSize: acceptedFilesInfo.maxFileSize,
      multiple: false,
    }),
  };
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone(dropZoneProps);
  const isMaxFilesLimitExceeded = useMemo(
    () =>
      uploadedFiles.length + rejectedFiles.length > acceptedFilesInfo.maxFiles,
    [uploadedFiles, rejectedFiles],
  );

  const errorToast = (
    <CustomSnackbar type="error" message={MODAL_CONTENT.toast_error_message} />
  );

  const successToast = (
    <CustomSnackbar
      type="success"
      message={MODAL_CONTENT.toast_success_message}
    />
  );

  const addFiles = (files, addFilesHandler) => {
    /* istanbul ignore else*/
    if (files.length > 0) {
      addFilesHandler([files[files.length - 1]]);
    }
  };
  const deleteFile = (fileIndex, fileHandler) => {
    fileHandler((prevFiles) =>
      prevFiles.filter((_, index) => fileIndex !== index),
    );
    if (rejectedFiles.length > 0) {
      setUploadError({
        flag: true,
        message: getError(
          fileRejections[0].errors[0].code,
          acceptedFilesInfo.maxFileSize,
          acceptedFilesInfo.fileNotSupportedErrorText,
        ),
      });
    }
  };
  useEffect(() => {
    if (acceptedFiles.length > 0)
      addFiles(
        acceptedFiles.map((file) => ({ file: file, id: uuid() })),
        setUploadedFiles,
      );
    setRejectedFiles([]);
  }, [acceptedFiles]);
  useEffect(() => {
    setIsSubmitDisabled(uploadError.flag);
  }, [uploadError]);
  useEffect(() => {
    if (rejectedFiles.length === 0) {
      setUploadError({ flag: false, message: '' });
    } else if (isMaxFilesLimitExceeded) {
      setUploadError({
        flag: true,
        message: maxFilesError(acceptedFilesInfo.maxFiles),
      });
    } else
      setUploadError({
        flag: true,
        message: getError(
          fileRejections[0].errors[0].code,
          acceptedFilesInfo.maxFileSize,
          acceptedFilesInfo.fileNotSupportedErrorText,
        ),
      });
  }, [rejectedFiles]);

  useEffect(() => {
    if (isMaxFilesLimitExceeded) {
      setUploadError({
        flag: true,
        message: maxFilesError(acceptedFilesInfo.maxFiles),
      });
    } else if (rejectedFiles.length === 0) {
      setUploadError({ flag: false, message: '' });
    } else {
      setUploadError({
        flag: acceptedFilesInfo.maxFileSize,
        message: getError(
          fileRejections[0]?.errors[0].code,
          acceptedFilesInfo.maxFileSize,
          acceptedFilesInfo.fileNotSupportedErrorText,
        ),
      });
    }
  }, [isMaxFilesLimitExceeded]);

  useEffect(() => {
    /* istanbul ignore else*/
    if (fileRejections.length > 0) {
      setUploadError({
        flag: true,
        message: getError(
          fileRejections[0].errors[0].code,
          acceptedFilesInfo.maxFileSize,
          acceptedFilesInfo.fileNotSupportedErrorText,
        ),
      });
      addFiles(
        fileRejections.map((fileItem) => ({ ...fileItem, id: uuid() })),
        setRejectedFiles,
      );
    }
  }, [fileRejections]);

  useEffect(() => {
    /* istanbul ignore else*/
    if (addContractOpen.isOpen) {
      clearUploadFileDetailsOnClose();
    }
  }, [addContractOpen.isOpen]);

  const onClose = () => {
    setAddContractOpen({ isOpen: false, participantId: null });
    setIsSubmitDisabled(false);
    refreshHandler();
  };

  const getExistingData = (prevData, year) => {
    return prevData.find((data) => data.projectCycleId === year.projectCycleId);
  };

  const updateActivityPracticeMapping = (data, cloneAndResetActivity) => {
    const newData = {};

    // Iterate over each key in the original data
    Object.keys(data).forEach((key) => {
      /* istanbul ignore else*/
      if (key !== 'totalAcres') {
        // Create a new array for the current key
        const activities = data[key] || [];
        newData[key] = activities.map(cloneAndResetActivity);
      }
    });

    return newData;
  };

  /**
   * @description Check if any incentiveRate is empty
   * @param {Object} existingData
   * @returns {Boolean}
   */
  const checkEmptyIncentiveRate = (existingData) => {
    return (
      existingData?.activityDetails &&
      Object.keys(existingData.activityDetails || {})
        .filter((key) => key !== 'totalAcres')
        .some((activityKey) =>
          existingData.activityDetails[activityKey].some((detail) =>
            isEmpty(detail.incentiveRate),
          ),
        )
    );
  };

  /**
   * @description  Generate error list if any incentiveRate is empty
   * @param {Boolean} hasEmptyIncentiveRate
   * @param {Object} existingData
   * @returns {Array}
   */
  const getincentiveUniqueErrorList = (hasEmptyIncentiveRate, existingData) => {
    return checkTernaryCondition(
      hasEmptyIncentiveRate && existingData,
      Object.keys(existingData?.activityDetails || {})
        .filter((key) => key !== 'totalAcres')
        .flatMap((activityKey) =>
          existingData.activityDetails[activityKey].map((detail) => ({
            projectActivityId: detail.projectActivityId,
            error: MODAL_CONTENT.incentive_rate_required_error,
          })),
        ),
      returnInitialErrorList(),
    );
  };

  useEffect(() => {
    const projectCycleStartYear = projectCycles.find(
      (cycle) => cycle.value === interestedCycleId,
    )?.cycleYear;
    let years = [];
    /* istanbul ignore else*/
    if (projectCycleStartYear)
      years.push({
        year: projectCycleStartYear,
        projectCycleId: interestedCycleId,
      });
    setTotalAcresPerYear((prevData) => {
      return years.map((year) => {
        const existingData = getExistingData(prevData, year);
        return (
          existingData || {
            projectCycleId: year.projectCycleId,
            totalContractedAcres: activityPracticeMapping.totalAcres ?? '',
            totalContractedAcresError: '',
          }
        );
      });
    });
    setProjectCycleYears(years);
  }, [projectCycles, interestedCycleId]);

  /**
   * @description Hfunction to get contracted acres error
   * @param {Object} existingError
   * @param {Object} existingData
   * @returns {String}
   */
  const getTotalContractedAcresError = (existingError, existingData) => {
    return existingError &&
      isSubmitDisabled &&
      isEmpty(existingData?.totalContractedAcres)
      ? existingError?.totalContractedAcresError
      : '';
  };

  const setProjectCycleYearsList = (endYearId) => {
    const projectCycleStartYear = projectCycles.find(
      (cycle) => cycle.value === interestedCycleId,
    )?.cycleYear;
    const projectCycleEndYear = projectCycles.find(
      (cycle) => cycle.value === endYearId,
    )?.cycleYear;
    //now loop from start to end and get all years if start and end year is not null or undefined
    let years = [];
    /* istanbul ignore else*/
    if (projectCycleStartYear)
      years.push({
        year: projectCycleStartYear,
        projectCycleId: interestedCycleId,
      });

    /* istanbul ignore else*/
    if (projectCycleStartYear && projectCycleEndYear) {
      for (let i = projectCycleStartYear + 1; i <= projectCycleEndYear; i++) {
        //get the projectCycleId from the year
        const projectCycleId = projectCycles.find(
          (cycle) => cycle.cycleYear === i,
        )?.value;
        /* istanbul ignore else*/
        if (projectCycleId)
          years.push({ year: i, projectCycleId: projectCycleId });
      }
    }
    const cloneAndResetActivity = (activity) => ({
      ...activity,
      contractedUnits: '',
      incentiveRate: activity.incentiveRate,
    });

    //update activityDataInfo with the new years and also the totalContractedAcresPerYear keeping the prev data intact
    setActivityDataInfo((prevData) => {
      return years.map((year) => {
        const existingData = getExistingData(prevData, year);

        // Check if any incentiveRate is empty
        const hasEmptyIncentiveRate = checkEmptyIncentiveRate(existingData);
        // Generate error list if any incentiveRate is empty
        const incentiveErrorListUnique = getincentiveUniqueErrorList(
          hasEmptyIncentiveRate,
          existingData,
        );
        return checkTernaryCondition(
          existingData,
          {
            ...existingData,
            incentiveErrorListUnique: checkTernaryCondition(
              isSubmitDisabled,
              incentiveErrorListUnique,
              [],
            ),
          },
          {
            projectCycleId: year.projectCycleId,
            activityDetails:
              year.projectCycleId === interestedCycleId
                ? activityPracticeMapping
                : updateActivityPracticeMapping(
                    activityPracticeMapping,
                    cloneAndResetActivity,
                  ),
            incentiveErrorListUnique,
          },
        );
      });
    });
    // now for the totalContractedAcresPerYear
    setTotalAcresPerYear((prevData) => {
      // Check if any existing year has a totalContractedAcresError
      const existingError = prevData.find(
        (data) => data.totalContractedAcresError !== '',
      );
      return years.map((year, index) => {
        const existingData = getExistingData(prevData, year);
        return checkTernaryCondition(
          existingData,
          {
            ...existingData,
            totalContractedAcresError: getTotalContractedAcresError(
              existingError,
              existingData,
            ),
          },
          {
            projectCycleId: year.projectCycleId,
            totalContractedAcres: checkTernaryCondition(
              index === 0,
              activityPracticeMapping.totalAcres ?? '',
              '',
            ),
            totalContractedAcresError: '',
          },
        );
      });
    });

    setProjectCycleYears(years);
  };

  function checkIncentiveError(practice) {
    let incentiveError = '';
    if (practice.incentiveRate === '') {
      incentiveError = MODAL_CONTENT.incentive_rate_required_error;
    } else if (Number(practice.incentiveRate) < 1) {
      incentiveError = MODAL_CONTENT.incentive_rate_min_limit_error;
    }
    return incentiveError;
  }

  const modalHasErrors = () => {
    for (const acresItem of totalAcresPerYear) {
      /* istanbul ignore else*/
      if (
        acresItem.totalContractedAcres === '' ||
        Number(acresItem.totalContractedAcres) === 0 ||
        acresItem.totalContractedAcres === '.'
      )
        return true;
    }

    for (const activity of activityDataInfo) {
      const activityDetails = Object.keys(activity.activityDetails).filter(
        (category) => category !== 'totalAcres',
      );

      for (const activityCategory of activityDetails) {
        const practices = activity.activityDetails[activityCategory];

        for (const practice of practices) {
          const incentiveError = checkIncentiveError(practice);
          /* istanbul ignore else*/
          if (incentiveError !== '') {
            return true;
          }
        }
      }
    }

    return (
      endProjectCycleId === null || hasProjectCycleError(endProjectCycleId)
    );
  };

  /**
   * @description check for error in incentiveRate from the state object : activtiyDateInfo loop for the incentiveRate value
   * @returns {Boolean} hasError
   */
  const getIncentiveRateError = () => {
    let hasError = false;
    activityDataInfo.forEach((item) => {
      // Iterate over each category in activityDetails
      Object.keys(item.activityDetails).forEach((category) => {
        /* istanbul ignore else*/
        if (category !== 'totalAcres') {
          item.activityDetails[category].forEach((practice) => {
            // Check and set the incentive error for each practice
            const incentiveError = checkIncentiveError(practice);
            /* istanbul ignore else*/
            if (!isEmpty(incentiveError)) {
              hasError = true;
            }
            // Find the corresponding error object in incentiveErrorListUnique
            const errorObj = item.incentiveErrorListUnique.find(
              (errorItem) =>
                errorItem.projectActivityId === practice.projectActivityId,
            );

            if (errorObj) {
              // Update the error message in the incentiveErrorListUnique
              errorObj.error = incentiveError;
            } else {
              // If no error object exists for this ID, add a new one

              item.incentiveErrorListUnique.push({
                projectActivityId: practice.projectActivityId,
                error: incentiveError,
              });
            }

            // Optionally, update the practice object itself if needed
            practice.error = incentiveError;
          });
        }
      });
    });
    return hasError;
  };

  useEffect(() => {
    /* istanbul ignore else*/
    if (isSubmitDisabled) {
      setIsSubmitDisabled(modalHasErrors());
    }
  }, [totalAcresPerYear, activityDataInfo, endProjectCycleId]);

  const onSubmit = () => {
    let hasError = false;
    //check for error in totalEnrolledAcres from the state object : totalAcresPerYear
    totalAcresPerYear.forEach((item) => {
      if (isEmpty(item.totalContractedAcres)) {
        hasError = true;
        item.totalContractedAcresError =
          MODAL_CONTENT.enrolled_acres_required_error;
      } else if (
        Number(item.totalContractedAcres) === 0 ||
        item.totalContractedAcres === '.'
      ) {
        hasError = true;
        item.totalContractedAcresError =
          MODAL_CONTENT.enrolled_acres_min_limit_error;
      }
    });
    //check for error in incentiveRate from the state object : activtiyDateInfo loop for the incentiveRate value
    hasError = hasError || getIncentiveRateError();
    // Cycle dropdown data not provided
    /* istanbul ignore else*/
    if (isEmpty(endProjectCycleId)) {
      hasError = true;
      setProjectEndCycleError(MODAL_CONTENT.project_cycle_required_error);
    }

    // End date is less than start date
    /* istanbul ignore else*/
    if (hasProjectCycleError(endProjectCycleId)) {
      hasError = true;
      setProjectEndCycleError(
        MODAL_CONTENT.project_cycle_less_than_start_error,
      );
    }

    scroll('.contract-modal-error');
    /* istanbul ignore else*/
    if (hasError) {
      setActivityDataInfo([...activityDataInfo]);

      setTotalAcresPerYear([...totalAcresPerYear]);
      setIsSubmitDisabled(true);
      return;
    }

    // THE API PART
    // FIRST RESTRUCTURE THE DATA TO BE SENT
    // for totalContractedAcresPerYear remove the error key from each of the object in the array and make a new object
    const totalContractedAcresPerYearUpdated = totalAcresPerYear.map((item) => {
      const { totalContractedAcresError, ...rest } = item;
      return rest;
    });

    const getFormattedObject = (item, category) => {
      return item.activityDetails[category].map((practice) => {
        return {
          projectCycleId: item.projectCycleId,
          projectActivityId: practice.projectActivityId,
          contractedUnits: Number(practice.contractedUnits),
          incentiveRate: Number(practice.incentiveRate),
        };
      });
    };
    // for activityDataInfo remove the error key and loop in each object and get the projectCycleId ,do and inner loop on activityDetails on array item under a key under that object and get the projectActivityId , contractedUnits and incentiveRate and incentive rate now store this 4 keys in an object accordingly , these objects will be stored in an array
    const formattedActivityData = activityDataInfo.flatMap((item) => {
      return Object.keys(item.activityDetails)
        .filter((category) => category !== 'totalAcres')
        .flatMap((category) => {
          return getFormattedObject(item, category);
        });
    });

    const getFormattedActivityObjectForPutApi = (item, category) => {
      return item.activityDetails[category].map((practice) => {
        return {
          projectCycleId: item.projectCycleId,
          projectActivityId: practice.projectActivityId,
          contractedUnits: Number(practice.contractedUnits),
          incentiveRate: Number(practice.incentiveRate),
          contractActivityIncentiveId: practice.contractActivityIncentiveId,
        };
      });
    };

    const addContractBody = {
      projectId,
      participantId,
      projectCycleEndId: endProjectCycleId,
      incentive: formattedActivityData,
      contractedArea: totalContractedAcresPerYearUpdated,
    };

    const formData = new FormData();
    /* istanbul ignore else*/
    if (uploadedFiles.length > 0) {
      formData.append('pdf', uploadedFiles[0].file);
    }
    formData.append(
      'contractData',
      new Blob([JSON.stringify(addContractBody)], { type: 'application/json' }),
    );

    if (addContractOpen.editMode) {
      //CALL PUT REQUEST  IF MODAL OPENED In EDIT MODE
      setLoading(true);
      //FORMAT THE DATA TO BE SENT
      const formattedActivityDataEdit = activityDataInfo.flatMap((item) => {
        return Object.keys(item.activityDetails).flatMap((category) => {
          return getFormattedActivityObjectForPutApi(item, category);
        });
      });
      const editContractBody = {
        projectId,
        participantId,
        projectCycleEndId: endProjectCycleId,
        incentive: formattedActivityDataEdit.map((activity) => ({
          projectCycleId: activity.projectCycleId,
          projectActivityId: activity.projectActivityId,
          contractedUnits: activity.contractedUnits,
          incentiveRate: activity.incentiveRate,
          contractActivityIncentiveId: activity.contractActivityIncentiveId,
        })),
        contractedArea: totalContractedAcresPerYearUpdated.map((area) => ({
          projectCycleId: area.projectCycleId,
          totalContractedAcres: area.totalContractedAcres,
          contractProjectCycleId: area.contractProjectCycleId,
        })),
      };
      const editFormData = new FormData();
      if (uploadedFiles.length > 0) {
        editFormData.append('pdf', uploadedFiles[0].file);
      }
      editFormData.append(
        'contractData',
        new Blob([JSON.stringify(editContractBody)], {
          type: 'application/json',
        }),
      );
      axios
        .put(ADD_CONTRACT, editFormData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(
          addContract(addContractOpen.projectId, addContractOpen.participantId),
        )
        .then(() => {
          setLoading(false);
          toast(successToast, toastStyle);
        })
        .catch(() => {
          toast(errorToast, toastStyle);
        })
        .finally(() => {
          setLoading(false);
          onClose();
        });
    } else {
      setLoading(true);

      axios
        .post(ADD_CONTRACT, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(
          addContract(addContractOpen.projectId, addContractOpen.participantId),
        )
        .then(() => toast(successToast, toastStyle))
        .catch(() => {
          toast(errorToast, toastStyle);
        })
        .finally(() => {
          setLoading(false);
          onClose();
        });
    }
  };

  const getStructuredActivityCategoryObject = (
    activityId,
    practice,
    key,
    value,
    incentiveError,
  ) => {
    const practiceKey = checkTernaryCondition(value === '.', '0.', value);
    return checkTernaryCondition(
      practice.projectActivityId === activityId,
      {
        ...practice,
        [key]: practiceKey,
        error: incentiveError,
      },
      practice,
    );
  };

  const updateActivityData = (
    data,
    activityId,
    value,
    activityCategory,
    key,
    incentiveError,
  ) => {
    return {
      ...data.activityDetails,
      [activityCategory]: data.activityDetails[activityCategory].map(
        (practice) =>
          getStructuredActivityCategoryObject(
            activityId,
            practice,
            key,
            value,
            incentiveError,
          ),
      ),
    };
  };

  const updateErrorInfo = (data, activityId, incentiveError) => {
    return data.incentiveErrorListUnique.map((errorItem) => {
      /* istanbul ignore else*/
      if (errorItem.projectActivityId === activityId) {
        return {
          ...errorItem,
          error: incentiveError,
        };
      }
      return errorItem;
    });
  };

  const getIncentiveError = (key, value) => {
    let incentiveError = '';
    /* istanbul ignore else*/
    if (key === MODAL_CONTENT.incentiveRate) {
      if (isEmpty(value)) {
        incentiveError = MODAL_CONTENT.incentive_rate_required_error;
      } else if (Number(value) < 1) {
        incentiveError = MODAL_CONTENT.incentive_rate_min_limit_error;
      }
    }
    return incentiveError;
  };

  const handlePracticeDataUpdate = (
    key,
    value,
    activityId,
    activityCategory,
    _year,
    projectCycleId,
  ) => {
    const decimalNumberRegex = /^\d{0,10}(\.\d{0,10})?$/;
    /* istanbul ignore else*/
    if (decimalNumberRegex.test(value) && Number(value) <= 50000000) {
      let incentiveError = getIncentiveError(key, value);

      setActivityDataInfo((prevData) => {
        // Check if the projectCycleId exists in the array
        const index = prevData.findIndex(
          (data) => data.projectCycleId === projectCycleId,
        );

        if (index !== -1) {
          // If found, update the existing object
          return prevData.map((data) => {
            /* istanbul ignore else*/
            if (data.projectCycleId === projectCycleId) {
              return {
                ...data,
                activityDetails: updateActivityData(
                  data,
                  activityId,
                  value,
                  activityCategory,
                  key,
                  incentiveError,
                ),
                incentiveErrorListUnique: updateErrorInfo(
                  data,
                  activityId,
                  incentiveError,
                ),
              };
            }
            return data;
          });
        } else {
          // If not found, create a new object with the initial structure
          const newActivityDetails = {
            ...activityPracticeMapping,
            [activityCategory]: activityPracticeMapping[activityCategory].map(
              (practice) =>
                getStructuredActivityCategoryObject(
                  activityId,
                  practice,
                  key,
                  value,
                  incentiveError,
                ),
            ),
          };
          const processObject = (practice) => {
            return {
              projectActivityId: practice.projectActivityId,
              error: '',
            };
          };
          const objectToBEReturned = (category) => {
            return activityPracticeMapping[category].map(processObject);
          };
          return [
            ...prevData,
            {
              projectCycleId: projectCycleId,
              activityDetails: newActivityDetails,
              incentiveErrorListUnique: Object.keys(
                activityPracticeMapping,
              ).flatMap((category) => {
                return objectToBEReturned(category);
              }),
            },
          ];
        }
      });
    }
  };

  const handleEnrolledAcresUpdate = (event, _year, projectCycleId) => {
    const decimalNumberRegex = /^\d{0,10}(\.\d{0,10})?$/;
    /* istanbul ignore else*/
    if (decimalNumberRegex.test(event.target.value)) {
      if (isEmpty(event.target.value)) {
        // update the enrolled Acres
        setTotalAcresPerYear((currentAcres) => {
          // If found, update the existing object
          return currentAcres.map((acres) =>
            checkTernaryCondition(
              acres.projectCycleId === projectCycleId,
              {
                ...acres,
                totalContractedAcresError:
                  MODAL_CONTENT.enrolled_acres_required_error,
              },
              acres,
            ),
          );
        });
      } else if (
        Number(event.target.value) === 0 ||
        event.target.value === '.'
      ) {
        setTotalAcresPerYear((currentAcres) => {
          // If found, update the existing object
          return currentAcres.map((acres) =>
            checkTernaryCondition(
              acres.projectCycleId === projectCycleId,
              {
                ...acres,
                totalContractedAcresError:
                  MODAL_CONTENT.enrolled_acres_min_limit_error,
              },
              acres,
            ),
          );
        });
      } else {
        setTotalAcresPerYear((currentAcres) => {
          // If found, update the existing object
          return currentAcres.map((acres) =>
            checkTernaryCondition(
              acres.projectCycleId === projectCycleId,
              {
                ...acres,
                totalContractedAcresError: '',
              },
              acres,
            ),
          );
        });
      }
      setTotalAcresPerYear((currentAcres) => {
        // Check if the projectCycleId already exists in the array
        const index = currentAcres.findIndex(
          (acres) => acres.projectCycleId === projectCycleId,
        );

        if (index !== -1) {
          // If found, update the existing object
          return currentAcres.map((acres) =>
            checkTernaryCondition(
              acres.projectCycleId === projectCycleId,
              {
                ...acres,
                totalContractedAcres: checkTernaryCondition(
                  event.target.value === '.',
                  '0.',
                  event.target.value,
                ),
              },
              acres,
            ),
          );
        } else {
          // If not found, add a new object
          return [
            ...currentAcres,
            {
              projectCycleId: projectCycleId,
              totalContractedAcres: checkTernaryCondition(
                event.target.value === '.',
                '0.',
                event.target.value,
              ),
            },
          ];
        }
      });
    }
  };

  const fetchProjectCycles = () => {
    setLoading(true);
    const URL = `${FETCH_PROJECT_CYCLES}/${projectId}`;
    axios
      .get(URL)
      .then((response) => {
        const data = response.data;

        const cyclesData = data.map((cycle) => {
          const endYear = new Date(cycle.cycleEndDate).getFullYear();
          return {
            label: `${endYear} (${convertDateFormat(
              cycle.cycleStartDate,
            )}-${convertDateFormat(cycle.cycleEndDate)})`,
            value: cycle.projectCycleId,
            cycleYear: endYear,
          };
        });

        setProjectCycles(cyclesData);
      })
      .catch(() => {
        /* do api error handling here */
      })
      .finally(() => setLoading(false));
  };

  const handleProjectCycleEndUpdate = (event) => {
    setEndProjectCycleId(event.target.value);
    setProjectCycleYearsList(event.target.value);
    if (hasProjectCycleError(event.target.value)) {
      setProjectEndCycleError(
        MODAL_CONTENT.project_cycle_less_than_start_error,
      );
    } else {
      setProjectEndCycleError('');
    }
    // use the state update logic here too for the statusPerYear but now for all the years
    const statusPerYearUpdated = updateStatusForAllYearsFromTheActivityMap(
      activityDataInfo,
      totalAcresPerYear,
      projectCycleYears,
    );

    setStatusPerYear(statusPerYearUpdated);
  };

  useEffect(() => {
    fetchProjectCycles();
  }, []);
  // useEffect to handle the change of status
  useEffect(() => {
    // get the current projectCycleId from the currentTab
    const projectCycleId = projectCycleYears[currentTab]?.projectCycleId;
    // get the activityDetails for this particular projectCycleId from activityDataInfo
    const activityDetails =
      activityDataInfo?.find((item) => item.projectCycleId === projectCycleId)
        ?.activityDetails ?? {};
    if (!isEmpty(activityDetails)) {
      const incentiveIncompleteStatus = getIncentiveIncompleteStatus(
        activityDetails,
        totalAcresPerYear,
        projectCycleId,
      );
      const { hasError } = calculateActivityErrors(
        activityDetails,
        totalAcresPerYear.find((item) => item.projectCycleId === projectCycleId)
          ?.totalContractedAcres,
      );
      const status = checkTernaryCondition(
        incentiveIncompleteStatus || hasError,
        STATUS.IN_PROGRESS,
        STATUS.COMPLETED,
      );
      const currentYear = projectCycleYears[currentTab]?.year;
      setStatusPerYear((prevStatus) => {
        return {
          ...prevStatus,
          [currentYear]: status,
        };
      });
    }
  }, [activityDataInfo, currentTab, totalAcresPerYear]);
  return (
    <DialogBox
      title={addContractOpen.editMode ? 'Edit contract' : 'Add Contract'}
      isOpen={addContractOpen.isOpen}
      customContentSx={{
        '::-webkit-scrollbar': {
          width: '4px',
        },

        '::-webkit-scrollbar-thumb': {
          backgroundColor: QUILL_GRAY,
          borderRadius: '3px',
        },
      }}
      onConfirm={() => {
        onSubmit();
      }}
      onCancel={() => {
        onClose();
      }}
      dialogTitleSx={dialogTitleSx}
      acceptCtnLabel={MODAL_CONTENT.submit}
      btnDisabled={isSubmitDisabled || acreExceedError}
      declineCtnLabel={MODAL_CONTENT.cancel}
      buttonGap="1rem"
      focusButtonPadding="0.53rem 0.9rem"
      buttonSX={DialogBoxButtonStyle}
      dialogActions
      darkGreenBtnColor>
      <Loader loading={loading} zIndex={99999} />
      <DateSectionWrapper>
        <FieldValueComponent
          label={MODAL_CONTENT.project_cycle_dropdown_title}
          fontWeight="400"
          color={DARK_CHARCOAL}
          labelMarginTop="0"
          labelFontSize="14px"
          fieldMarginBottom="0"
          labelMarginBottom="0"
        />
        <InstructionText>
          {MODAL_CONTENT.project_cycle_instruction}
        </InstructionText>
        <FieldSectionWrapper marginBottom="0rem" columnGap="1rem">
          <DropDown
            label="Start project cycle"
            isDisabled
            hasNoBottomMargin={true}
            value={interestedCycleId}
            dropdownlist={projectCycles}
            {...dropdownStyleProps}
          />
          <DropDown
            isDisabled={addContractOpen.editMode}
            label="End project cycle"
            hasNoBottomMargin={true}
            value={checkTernaryCondition(
              endProjectCycleId === null,
              MODAL_CONTENT.project_cycle_dropdown_placeholder,
              endProjectCycleId,
            )}
            dropDownPlaceholder={
              MODAL_CONTENT.project_cycle_dropdown_placeholder
            }
            dropdownlist={projectCycles}
            onUpdate={handleProjectCycleEndUpdate}
            error={projectEndCycleError?.length > 0}
            {...dropdownStyleProps}
          />
        </FieldSectionWrapper>
        {projectEndCycleError?.length > 0 &&
          projectEndCycleError !==
            MODAL_CONTENT.project_cycle_required_error && (
            <ErrorWrapper
              className={checkTernaryCondition(
                projectEndCycleError?.length > 0,
                'contract-modal-error',
                '',
              )}>
              {projectEndCycleError}
            </ErrorWrapper>
          )}
      </DateSectionWrapper>
      <div>
        <DropBoxStyle>
          <DragAndDropTitle>
            {MODAL_CONTENT.upload_title}
            {(uploadError.flag || isMaxFilesLimitExceeded) && (
              <AlertIcon data-testid="upload-error-icon" />
            )}
          </DragAndDropTitle>
          <DragAndDropTitle color={COOL_GRAY} fontSize="0.75rem">
            {MODAL_CONTENT.optional}
          </DragAndDropTitle>
        </DropBoxStyle>
        <SmallUploadWrapper>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} data-testid="fileDropzone" />
            <DragAndDropText>
              {MODAL_CONTENT.dropzone_info_text}
              <Subtext>{MODAL_CONTENT.browse}</Subtext>.
            </DragAndDropText>
          </div>
        </SmallUploadWrapper>
        {acceptedFilesInfo.infoTextList?.map((infoText) => (
          <UploadHelpText
            key={infoText}
            invisible={uploadError.flag || isMaxFilesLimitExceeded}>
            {infoText}
          </UploadHelpText>
        ))}
        {uploadError.flag && (
          <UploadHelpText iserror={true}>{uploadError.message}</UploadHelpText>
        )}
        {uploadedFiles.length > 0 &&
          uploadedFiles.map((fileItem, index) => (
            <AcceptedFileItem key={fileItem.id}>
              <FileName isError={isMaxFilesLimitExceeded}>
                {fileItem.file.name.slice(0, 55)}...
              </FileName>
              <DeleteOutlinedIcon
                data-testid="deleteIcon"
                fontSize="large"
                sx={deleteIconSx}
                onClick={() => deleteFile(index, setUploadedFiles)}
              />
            </AcceptedFileItem>
          ))}
        {rejectedFiles.map(({ file, id }, index) => (
          <AcceptedFileItem key={id}>
            <FileName isError={true}>{file.name}</FileName>
            <DeleteOutlinedIcon
              data-testid="deleteIcon"
              fontSize="medium"
              sx={deleteIconSx}
              onClick={() => deleteFile(index, setRejectedFiles)}
            />
          </AcceptedFileItem>
        ))}
      </div>
      <Typography variant="h6" sx={tabHeadingSx}>
        {MODAL_CONTENT.contractedAcresAndIncentives}
      </Typography>
      <Box sx={{ marginBottom: '0.5rem', width: '31.75rem', height: '2.5rem' }}>
        <TabberBox>
          <Tabs
            visibleScrollbar={false}
            selectionFollowsFocus
            variant="scrollable"
            scrollButtons={false}
            value={currentTab}
            TabIndicatorProps={{
              style: {
                backgroundColor: TABS_HIGHLIGHT_BORDER,
              },
            }}
            onChange={handleTabChange}>
            {tabData.map((tab, index) => (
              <Tab
                role="tab"
                style={{
                  '&. MuiTabs-indicator': {
                    backgroundColor: TABS_HIGHLIGHT_BORDER,
                    borderBottom: `3px solid ${TABS_HIGHLIGHT_BORDER}`,
                  },
                }}
                sx={TabSx}
                className={classNames({
                  'active-tab': currentTab === index,
                })}
                key={tab.title}
                label={
                  <TabsStyle>
                    {tab.title}
                    {checkTernaryCondition(
                      isEmpty(statusPerYear[tab.title]) ||
                        statusPerYear[tab.title] === STATUS.IN_PROGRESS,
                      <GreyIcon2 />,
                      <GreenIcon />,
                    )}
                  </TabsStyle>
                }
                data-testid={`tab-${index}`}
                {...tabProps(index)}
              />
            ))}
          </Tabs>
        </TabberBox>
      </Box>
      {tabData.map((tab, index) => (
        <TabPanel key={tab.title} value={currentTab} index={index}>
          {tab.content}
        </TabPanel>
      ))}
    </DialogBox>
  );
};

AddContractModal.propTypes = {
  addContractOpen: PropTypes.bool,
  setAddContractOpen: PropTypes.func,
  refreshHandler: PropTypes.func,
  activityPracticeMapping: PropTypes.object,
  projectId: PropTypes.number,
  interestedCycleId: PropTypes.number,
  participantId: PropTypes.number,
  contractId: PropTypes.number,
};

export default AddContractModal;
