import { checkTernaryCondition, isEmpty, sortFieldsByName } from 'utils/helper';
import PropTypes from 'prop-types';
import { ReactComponent as InProgressIcon } from '../../../../assets/icons/InProgressIcon.svg';
import { ReactComponent as GreenTickIcon } from '../../../../assets/icons/ActivityStatus.svg';
import { ReactComponent as YellowStatus } from '../../../../assets/icons/yellowStatus.svg';
import { ReactComponent as GreenStatus } from '../../../../assets/icons/greenStatus.svg';
import { ReactComponent as NotStartedStatus } from '../../../../assets/icons/NotStartedStatus.svg';

import {
  FieldWrapper,
  IconWrapper,
  MainWrapper,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  iconStyles,
  fullWidth,
  getFieldPadding,
  getFieldBackgroundColor,
  FieldLeftBorderDiv,
  FarmsAndFieldsWrapperPadding,
  AccordianSummaryWithMapViewLink,
  getFarmAndFieldsPadding,
  FarmHeaderWrapper,
  StatusHeaderWrapper,
  projectNameStyle,
  getFieldPaddingWhenSelected,
} from './LeftMenuFieldData.style';
import { ReactComponent as UpdatedCircleIcon } from '../../../../assets/icons/updatedCircle.svg';
import {
  GLOBAL_BLUE_HOVER,
  GLOBAL_BLUE,
  HOVER_LIGHT_BLUE,
  WHITE,
  BLUISH_CYAN,
} from 'theme/GlobalColors';
import { useMemo, useState } from 'react';
import ControlledInput from './ControlledInput/ControlledInput.react';
import {
  PAGE_CONTENT,
  deleteModalInit,
  farmAndFieldTabStatus,
  getFarmTabColor,
} from './LeftMenuFieldData.content';

import { LEFT_MENU_SECTION_HEADERS } from 'pages/ParticipantDataCollection/ParticipantDataCollection.content';
import { Backdrop, CircularProgress } from '@mui/material';
import { backdropSx } from 'pages/ParticipantDataCollection/ParticipantDataCollection.style';
import AddSectionButton from 'components/AddSectionButton/AddSection.react';
import OverFlowTooltip from 'components/OverFLowTooltip/OverFLowTooltip.react';

const IconStatusHandler = (status) => {
  switch (status) {
    case 'complete':
    case 'completed':
      return <GreenTickIcon data-testid="complete" style={iconStyles} />;
    case 'in progress':
      return <InProgressIcon data-testid="in progress" style={iconStyles} />;
    default:
      return <UpdatedCircleIcon style={iconStyles} />;
  }
};

const LeftMenuFieldData = ({
  heading,
  // Note: Here fieldValue is the farm object
  fieldValue,
  hasCollapsableOptions,
  showFields,
  toggleFieldsDataView,
  insideEnrollmentForm,
  setRightSectionId,
  updateFarmFieldName,
  currentFarmId,
  setCurrentFarmId,
  setSelectedFieldId,
  setShowFieldInfoForm,
  farmInfoCurrentTab,
  setFarmInfoCurrentTab,
  setExpandedFarmId,
  farmInfo,
  setFarmInfo,
  selectedFieldId,
  setFieldPolygons,
  rightSectionId,
  isInBaselinePage,
  showFieldInfoForm,
  isFarmSelected,
  expandedFarmId,
  setIsFieldDeleted = () => {
    /* do nothing */
  },
  resetFieldViewTab = () => {
    /* do nothing */
  },
  enrolledFarmIds,
  enrolledFieldIds,
  handleAccordianSummaryDeleteClick = () => {
    /* do nothing */
  },
  isParticipantContributor,
  handleAccordianSummaryClick = () => {
    /* do nothing */
  },
  handleAccordianItemClick = () => {
    /* do nothing */
  },
  isInsideActivityReporting = false,
}) => {
  const [hoveredFieldId, setHoveredFeildId] = useState(-1);
  const [isFarmHovered, setIsFarmHovered] = useState(false);
  const totalFields = fieldValue?.fields?.length;
  const totalFieldCompleted = fieldValue?.fields?.reduce((acc, obj) => {
    if (obj.status === 'Completed') {
      return acc + 1;
    } else {
      return acc;
    }
  }, 0);
  const inFarmAndFieldsSection =
    heading === LEFT_MENU_SECTION_HEADERS.farmAndFieldsSection;
  const inParticipantProfileSection =
    heading === LEFT_MENU_SECTION_HEADERS.profileSection;
  const inFarmAndContributorTab =
    heading === LEFT_MENU_SECTION_HEADERS.farmAndContributorSection;

  const disableCurrentFarm = useMemo(() => {
    if (!enrolledFarmIds || !fieldValue?.id) return false;
    return enrolledFarmIds.includes(fieldValue.id);
  }, [enrolledFarmIds, fieldValue.id]);

  const handleOnChange = (newValue, field) => {
    updateFarmFieldName({
      farmId: fieldValue.id,
      fieldId: field.id,
      name: newValue,
    });
  };

  const setStatusIcon = () => {
    const isCompleted = totalFields === totalFieldCompleted;
    const allNotStarted = fieldValue?.fields?.every(
      (field) => field.status === farmAndFieldTabStatus.notStarted,
    );
    const anyInProgress = fieldValue?.fields?.some(
      (field) =>
        field.status === farmAndFieldTabStatus.inProgress ||
        field.status === farmAndFieldTabStatus.completed,
    );
    if (allNotStarted && totalFields > 0) {
      return <NotStartedStatus />;
    } else if (isCompleted) {
      return <GreenStatus />;
    } else if (anyInProgress) {
      return <YellowStatus />;
    }
  };

  const getAccrodianColors = (flag) => {
    if (isFarmHovered) {
      return checkTernaryCondition(
        flag === 'bgColor',
        HOVER_LIGHT_BLUE,
        GLOBAL_BLUE_HOVER,
      );
    }
    const baselineFarmSelected =
      isInBaselinePage && currentFarmId === fieldValue.id && isFarmSelected;
    const profileSectionOpened =
      inParticipantProfileSection && rightSectionId === 1;
    const enrollmentFarmOpened =
      inFarmAndFieldsSection &&
      rightSectionId === 2 &&
      currentFarmId === fieldValue.id &&
      !showFieldInfoForm &&
      farmInfoCurrentTab === 0;
    const farmAndContributorTabOpened =
      inFarmAndContributorTab && rightSectionId === 3;
    if (
      baselineFarmSelected ||
      profileSectionOpened ||
      enrollmentFarmOpened ||
      farmAndContributorTabOpened
    ) {
      return getFarmTabColor(flag === 'bgColor');
    } else {
      return checkTernaryCondition(flag === 'bgColor', 'white', GLOBAL_BLUE);
    }
  };

  const handleFieldClick = (field, farmId, farmName) => {
    setCurrentFarmId(farmId);

    if (isInsideActivityReporting || isInBaselinePage) {
      handleAccordianItemClick({
        targetId: field?.id,
        targetName: field?.value,
        targetFarmId: farmId,
        targetFarmName: farmName,
      });
    } else {
      handleAccordianItemClick({
        targetPlace: 'Field',
        targetId: field?.id,
        targetName: field?.value,
        targetParentId: farmId,
        targetParentName: farmName,
      });
    }
  };

  const completedFieldVsTotalField = `${totalFieldCompleted} / ${totalFields} fields complete`;

  return (
    <Accordion expanded={showFields}>
      <AccordionSummary
        collapsable={hasCollapsableOptions}
        toggleAccordion={toggleFieldsDataView}
        marginBottom={'0rem'}
        iconMarginTop={disableCurrentFarm && '1.75rem'}
        backgroundColor={getAccrodianColors('bgColor')}
        onMouseEnter={() => setIsFarmHovered(true)}
        onMouseLeave={() => setIsFarmHovered(false)}>
        <AccordianSummaryWithMapViewLink>
          <FarmHeaderWrapper
            onClick={() => {
              resetFieldViewTab();

              if (isInsideActivityReporting || isInBaselinePage) {
                handleAccordianSummaryClick({
                  targetId: fieldValue?.id,
                  targetName: fieldValue?.value,
                });
              } else {
                handleAccordianSummaryClick({
                  targetPlace: heading,
                  targetId: fieldValue?.id,
                  targetName: fieldValue?.value,
                });
              }
            }}>
            <MainWrapper
              padding={checkTernaryCondition(
                insideEnrollmentForm,
                inFarmAndFieldsSection &&
                  getFarmAndFieldsPadding(isFarmHovered),
                FarmsAndFieldsWrapperPadding,
              )}
              width={insideEnrollmentForm && inFarmAndFieldsSection && '100%'}>
              <FieldWrapper
                color={getAccrodianColors('')}
                selected={
                  isFarmSelected ||
                  (fieldValue?.id === currentFarmId &&
                    !showFieldInfoForm &&
                    !isInBaselinePage) ||
                  (inParticipantProfileSection && rightSectionId === 1)
                }
                width={checkTernaryCondition(
                  fieldValue.id !== 1,
                  checkTernaryCondition(isInBaselinePage, '', '10%'),
                  '90%',
                )}
                minWidth="6.25rem">
                <OverFlowTooltip
                  stylesSx={projectNameStyle(fieldValue?.id)}
                  value={fieldValue.value}></OverFlowTooltip>
              </FieldWrapper>
              {fieldValue.id !== 1 && !isInBaselinePage && (
                <AddSectionButton
                  onClickHanlder={(e) => {
                    e.stopPropagation();
                    handleAccordianItemClick({
                      targetPlace: 'Add fields',
                      targetParentId: fieldValue?.id,
                      targetName: '',
                    });
                  }}
                  label={PAGE_CONTENT.add_fields}
                  marginTop={10}
                  marginBottom={10}
                  hasBorder={true}
                  backgroundColor={WHITE}
                  padding="0rem 0.5rem"
                />
              )}
              <IconWrapper paddingLeft={isInBaselinePage}>
                {IconStatusHandler(fieldValue.status?.toLowerCase())}
              </IconWrapper>
            </MainWrapper>
            {(isInBaselinePage || isParticipantContributor) && (
              <StatusHeaderWrapper data-testid="sub-section">
                {setStatusIcon()}
                {completedFieldVsTotalField}
              </StatusHeaderWrapper>
            )}
          </FarmHeaderWrapper>
          <div>
            {expandedFarmId === fieldValue.id &&
              fieldValue?.fields?.length > 0 && (
                <FieldLeftBorderDiv farmTab height="0%" />
              )}
          </div>
        </AccordianSummaryWithMapViewLink>
      </AccordionSummary>
      <AccordionDetails>
        {!isEmpty(fieldValue?.fields) &&
          sortFieldsByName(fieldValue?.fields)?.map((field, index) => {
            const disableField = enrolledFieldIds?.includes(field.id);
            return (
              <MainWrapper
                data-testid="field-data"
                key={field.id}
                width={fullWidth}
                padding={checkTernaryCondition(
                  (selectedFieldId?.id === field.id && showFieldInfoForm) ||
                    (selectedFieldId?.id === field.id &&
                      !isFarmSelected &&
                      isInBaselinePage),
                  getFieldPaddingWhenSelected(
                    insideEnrollmentForm && hoveredFieldId === field.id,
                  ),
                  getFieldPadding(
                    insideEnrollmentForm && hoveredFieldId === field.id,
                  ),
                )}
                backgroundColor={getFieldBackgroundColor(
                  hoveredFieldId === field.id,
                  checkTernaryCondition(
                    insideEnrollmentForm,
                    selectedFieldId?.id === field.id && showFieldInfoForm,
                    selectedFieldId?.id === field.id,
                  ),
                  isFarmSelected,
                )}
                borderLeft={checkTernaryCondition(
                  isInBaselinePage,
                  checkTernaryCondition(
                    selectedFieldId?.id === field.id && !isFarmSelected,
                    `5px solid ${BLUISH_CYAN}`,
                    '',
                  ),
                  checkTernaryCondition(
                    selectedFieldId?.id === field.id && showFieldInfoForm,
                    `5px solid ${BLUISH_CYAN}`,
                    '',
                  ),
                )}
                onMouseEnter={() => setHoveredFeildId(field.id)}
                onMouseLeave={() => setHoveredFeildId(-1)}
                onClick={() =>
                  handleFieldClick(field, fieldValue.id, fieldValue.value)
                }>
                <ControlledInput
                  disabled={disableField}
                  fieldSelected={
                    selectedFieldId?.id === field.id &&
                    (showFieldInfoForm || (isInBaselinePage && !isFarmSelected))
                  }
                  isInBaselinePage={isInBaselinePage}
                  hoveredFieldId={checkTernaryCondition(
                    insideEnrollmentForm,
                    hoveredFieldId,
                    -1,
                  )}
                  field={field}
                  setValue={(newValue) => handleOnChange(newValue, field)}
                  farmInfo={checkTernaryCondition(
                    insideEnrollmentForm,
                    farmInfo,
                    [],
                  )}
                  isFieldInCurrentFarm={fieldValue.id === currentFarmId}
                />
                <IconWrapper paddingLeft={isInBaselinePage}>
                  {IconStatusHandler(field.status?.toLowerCase())}
                </IconWrapper>
              </MainWrapper>
            );
          })}
      </AccordionDetails>
    </Accordion>
  );
};

LeftMenuFieldData.propTypes = {
  heading: PropTypes.string,
  fieldValue: PropTypes.object,
  hasCollapsableOptions: PropTypes.bool,
  showFields: PropTypes.bool,
  toggleFieldsDataView: PropTypes.func,
  insideEnrollmentForm: PropTypes.bool,
  setRightSectionId: PropTypes.func,
  updateFarmFieldName: PropTypes.func,
  currentFarmId: PropTypes.string,
  setCurrentFarmId: PropTypes.func,
  setSelectedFieldId: PropTypes.func,
  setShowFieldInfoForm: PropTypes.func,
  farmInfoCurrentTab: PropTypes.number,
  setFarmInfoCurrentTab: PropTypes.func,
  setExpandedFarmId: PropTypes.func,
  farmInfo: PropTypes.array,
  setFarmInfo: PropTypes.func,
  selectedFieldId: PropTypes.string,
  setFieldPolygons: PropTypes.func,
  rightSectionId: PropTypes.string,
  isInBaselinePage: PropTypes.bool,
  showFieldInfoForm: PropTypes.bool,
  setFarmDetails: PropTypes.func,
  isFarmSelected: PropTypes.bool,
  expandedFarmId: PropTypes.string,
  setIsFieldDeleted: PropTypes.func,
  resetFieldViewTab: PropTypes.func,
  enrolledFarmIds: PropTypes.array,
  enrolledFieldIds: PropTypes.array,
  handleAccordianSummaryDeleteClick: PropTypes.func,
  handleAccordianSummaryClick: PropTypes.func,
  handleAccordianItemClick: PropTypes.func,
  isParticipantContributor: PropTypes.bool,
  isInsideActivityReporting: PropTypes.bool,
};

export default LeftMenuFieldData;
