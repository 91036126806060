import { uniqueId } from 'utils/uniqueIdGenerator';
import ModalLessAccordionDisplayBox from '../ModalLessAccordionDisplayBox/ModalLessAccordionDisplayBox.react';
import {
  OperationsDataWrapper,
  TextWrapper,
} from './DetailedOperationsDataComp.style';
import { useContext, useEffect, useState } from 'react';
import {
  editDeleteModalTypeMap,
  findDataForSingleModalDelete,
  initialDeleteState,
  ORIGINATION_DATA_CONTENT,
} from './DetailedOperationsDataComp.content';
import NitrogenFertilizerWrapper from 'pages/ParticipantDataCollection/BaseLineFieldInfo/components/modals/NitrogenFertilizerModal/NitrogenFertilizerWrapper/NitrogenFertilizerWrapper.react';
import AddCropModal from 'pages/ParticipantDataCollection/BaseLineFieldInfo/components/modals/AddCropModal/AddCropModal.react';
import DialogBox from 'components/DialogBox/DialogBox.react';
import TillageModalWrapper from 'pages/ParticipantDataCollection/BaseLineFieldInfo/components/modals/TillageModal/TillageModalWrapper/TillageModalWrapper.react';
import { implementedActivitiesWithOpsDataContext } from 'contextAPI/implementedActivitiesWithOpsDataContext';
import { DARK_BLACK_80_PERCENT, DARK_CHARCOAL } from 'theme/GlobalColors';
import {
  cleanDatesAndIds,
  extractBaselineFieldData,
} from '../TabBodyComponent.functions';
import {
  checkTernaryCondition,
  whenValueIsUndefinedReturnEmptyString,
} from 'utils/helper';
import { PAGE_CONTENT } from 'pages/ParticipantDataCollection/BaseLineReporting/BaseLineReportingMainPage.content';
import Proptypes from 'prop-types';

const modalOpenState = { flag: false, id: 0, data: null, currentRowId: 0 };

const DetailedOperationsDataComp = ({
  year,
  makeFormDirty,
  sampleId,
  setFieldDetails,
  setEnrollmentYears,
  disableAllFieldActions = false,
  currentFarmId,
  currentFieldId,
}) => {
  const [isNitrogenModalOpen, setIsNitrogenModalOpen] =
    useState(modalOpenState);
  const [isAddCropModalOpen, setIsAddCropModalOpen] = useState(modalOpenState);
  const [isTillageModalOpen, setIsTillageModalOpen] = useState(modalOpenState);
  const [replacePromptItems, setReplacePromptItems] = useState([]);
  const [replacePromptIndex, setReplacePromptIndex] = useState(-1);
  const [isReplacePromptOpen, setIsReplacePromptOpen] = useState(false);
  const {
    operationsData,
    setOperationsData,
    setDeletePracticeModalsData,
    deleteData,
    setDeleteData,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    isCropDeleteModalOpen,
    setIsCropDeleteModalOpen,
    tillageType,
    isInBaselineYear,
  } = useContext(implementedActivitiesWithOpsDataContext);

  const submitPracticeHandler = (data, id, modalType) => {
    setOperationsData((prevState) => {
      const updatedData = { ...prevState };
      const modalTypeMap = {
        nitrogenList: ORIGINATION_DATA_CONTENT.fertilizer_list,
        fieldSampleCrops: ORIGINATION_DATA_CONTENT.fieldSampleCrops,
        tillageTypeDtoList: ORIGINATION_DATA_CONTENT.tillage_list,
        cropList: ORIGINATION_DATA_CONTENT.crop_list,
      };
      const listToUpdate = modalTypeMap[modalType];
      const prevList = prevState[listToUpdate] || [];
      if (listToUpdate && prevList) {
        const newList = prevList.slice();
        const existingIndex = newList.findIndex((item) => item.id === id);

        if (existingIndex !== -1) {
          newList[existingIndex] = {
            id: id,
            data: { ...data, id: id },
            year: year,
          };
        } else {
          newList.push({
            id: id,
            data: { ...data, id: id },
            year: year,
          });
        }
        updatedData[listToUpdate] = newList;
      }
      return updatedData;
    });
  };
  const handleCropDelete = () => {
    setIsCropDeleteModalOpen({ flag: false, id: null });
    setOperationsData({
      ...operationsData,
      cropList: operationsData?.cropList.filter(
        (item) => item.id !== isCropDeleteModalOpen.id,
      ),
    });
    updateEnrollmentYears();
    makeFormDirty();
  };

  const copyCrops = ({ data: cashCropList, combinations }) => {
    makeFormDirty();
    const newCashcropDataList = cashCropList?.map((cashCropData) => {
      let { cashCropList } = extractBaselineFieldData(cashCropData);
      const newCropSampleId = uniqueId();

      cashCropList = cashCropList.filter((_, index) => index === 0);
      cashCropList[0].id = newCropSampleId;
      cashCropList[0].cropSampleId = newCropSampleId;
      cashCropList[0].cropTerminationDate = '';
      cashCropList[0].cropPlantDate = '';

      const irrigationList = cleanDatesAndIds(
        cashCropList[0]?.irrigationList,
        newCropSampleId,
      );

      const soilAmendmentList = cleanDatesAndIds(
        cashCropList[0]?.soilAmendmentList,
        newCropSampleId,
      );
      return {
        cashCrop: {
          ...cashCropList[0],
          isCopied: true,
          irrigationList,
          soilAmendmentList,
        },
      };
    });
    let uniqueItems = [],
      repeatedItems = [];

    for (const cashCropData of newCashcropDataList) {
      const isUnique =
        operationsData?.cropList?.findIndex(
          (cashcrop) =>
            cashcrop?.data?.cropId === cashCropData.cashCrop?.cropId,
        ) === -1;

      if (isUnique) uniqueItems.push(cashCropData);
      else {
        const repeatingCombination = combinations.find(
          (combination) =>
            +combination.dropdown3Value === +cashCropData.cashCrop?.cropId,
        );
        const modalTitle = `${repeatingCombination?.dropdown1Label} - ${repeatingCombination?.dropdown2Label} - ${repeatingCombination?.dropdown3Label}`;
        repeatedItems.push({
          data: cashCropData,
          titlePart: modalTitle,
          replaceItemIdKey: 'cropId',
        });
      }
    }

    setReplacePromptItems(repeatedItems);
    setReplacePromptIndex(0);

    setOperationsData((prev) => {
      const id = uniqueId();
      return {
        ...prev,
        cropList: [
          ...prev.cropList,
          ...uniqueItems.map((item, index) => ({
            id: `${id}-${index}`,
            data: { ...item.cashCrop, id: `${id}-${index}` },
            year,
          })),
        ],
      };
    });

    setFieldDetails?.((prev) => {
      return {
        ...prev,
        farmDetails: prev.farmDetails?.map((farm) => {
          return checkTernaryCondition(
            farm.farmId === currentFarmId,
            {
              ...farm,
              mainStatus: ORIGINATION_DATA_CONTENT.IN_PROGRESS,
              fieldDetails: farm.fieldDetails.map((field) => {
                return checkTernaryCondition(
                  field.fieldId === currentFieldId,
                  {
                    ...field,
                    mainStatus: ORIGINATION_DATA_CONTENT.IN_PROGRESS,
                  },
                  field,
                );
              }),
            },
            farm,
          );
        }),
      };
    });

    updateEnrollmentYears();
  };

  const updateDeletePracticeModalsData = (removedObjects, modalDeleteList) => {
    setDeletePracticeModalsData((prevDeletePracticeModalsData) => ({
      ...prevDeletePracticeModalsData,
      [modalDeleteList]: [
        ...prevDeletePracticeModalsData[modalDeleteList],
        ...removedObjects,
      ],
    }));
  };

  const acceptReplace = (replaceItem) => {
    const cropIdToBeReplaced = replaceItem?.cashCrop?.cropId;
    const cropSampleIdToBeReplaced = operationsData.cropList.find(
      (item) => item.data.cropId === cropIdToBeReplaced,
    )?.id;

    const filteredCropList = operationsData.cropList.filter(
      (item) => item.id !== cropSampleIdToBeReplaced,
    );

    setOperationsData((prev) => {
      return {
        ...prev,
        cropList: [
          ...filteredCropList,
          {
            id: uniqueId(),
            data: replaceItem.cashCrop,
            year,
          },
        ],
      };
    });

    setIsReplacePromptOpen(false);
    setReplacePromptIndex((prev) => prev + 1);
    updateEnrollmentYears();
    makeFormDirty();
  };

  const declineReplace = () => {
    setIsReplacePromptOpen(false);
    setReplacePromptIndex((prev) => prev + 1);
  };

  const updateEnrollmentYears = () => {
    setEnrollmentYears?.((prev) =>
      prev.map((yearData) => {
        return checkTernaryCondition(
          yearData.sampleId === sampleId,
          {
            ...yearData,
            status: ORIGINATION_DATA_CONTENT.IN_PROGRESS,
          },
          yearData,
        );
      }),
    );
  };

  const handleSinglePracticeDeletion = (
    deleteData,
    modalTypePracticeList,
    modalDeleteList,
  ) => {
    setOperationsData({
      ...operationsData,
      [modalTypePracticeList]: findDataForSingleModalDelete(
        deleteData,
        operationsData,
        modalTypePracticeList,
      )[1],
    });
    updateDeletePracticeModalsData(
      findDataForSingleModalDelete(
        deleteData,
        operationsData,
        modalTypePracticeList,
      )[0],
      modalDeleteList,
    );
  };
  const handleModalLessDelete = () => {
    const targetModal = editDeleteModalTypeMap[deleteData?.modalType];
    const modalTypePracticeList = targetModal?.practiceList;
    const modalDeleteList = targetModal?.deletedPracticeList;
    if (modalTypePracticeList) {
      handleSinglePracticeDeletion(
        deleteData,
        modalTypePracticeList,
        modalDeleteList,
      );
    }
    const filterData = operationsData.nitrogenList.filter(
      (item) => item.id !== deleteData?.id,
    );
    setOperationsData((prev) => ({ ...prev, nitrogenList: filterData }));
    updateEnrollmentYears();
    makeFormDirty();
    setDeleteData({
      id: null,
      modalType: '',
      practiceTypeValue: '',
      deleteModalHeading: '',
      deleteModalContent: '',
    });
  };
  const handleDeleteData = (modalType, practiceTypeValue) => {
    return {
      deleteModalHeading: `${ORIGINATION_DATA_CONTENT.delete_heading_first_part} ${year} - ${modalType} - ${practiceTypeValue}`,
      deleteModalContent: `${ORIGINATION_DATA_CONTENT.delete_content_firt_part} ${year} - ${modalType} - ${practiceTypeValue}${ORIGINATION_DATA_CONTENT.delete_content_second_part}`,
    };
  };
  useEffect(() => {
    if (deleteData?.id !== null) {
      const { deleteModalHeading, deleteModalContent } = handleDeleteData(
        deleteData?.modalType,
        deleteData?.practiceTypeValue,
      );
      setDeleteData({
        ...deleteData,
        deleteModalHeading: deleteModalHeading,
        deleteModalContent: deleteModalContent,
      });
    }
  }, [deleteData?.id, deleteData.practiceTypeValue]);

  useEffect(() => {
    if (
      replacePromptIndex >= 0 &&
      replacePromptIndex < replacePromptItems?.length
    ) {
      setIsReplacePromptOpen(true);
    }
  }, [replacePromptIndex, replacePromptItems]);
  return (
    <div>
      <TextWrapper
        fontSize={'1rem'}
        fontWeight={600}
        lineHeight={'22.4px'}
        color={DARK_BLACK_80_PERCENT}
        marginBottom={'0.5rem'}
        gap={'0.25rem'}>
        <TextWrapper>
          {ORIGINATION_DATA_CONTENT.detailed_operations_data}
        </TextWrapper>
        {isInBaselineYear && (
          <TextWrapper fontWeight={400}>
            {ORIGINATION_DATA_CONTENT.required_field}
          </TextWrapper>
        )}
      </TextWrapper>
      <TextWrapper
        fontSize={'0.875rem'}
        fontWeight={400}
        lineHeight={'21px'}
        color={DARK_BLACK_80_PERCENT}
        letterSpacing={'-1.1%'}
        marginBottom={'1rem'}>
        {ORIGINATION_DATA_CONTENT.detailed_operations_data_sub_heading}
      </TextWrapper>
      <OperationsDataWrapper>
        <ModalLessAccordionDisplayBox
          currentYear={year}
          fieldId={currentFieldId}
          mainLabel={ORIGINATION_DATA_CONTENT.fertilizer}
          jumpClassName={ORIGINATION_DATA_CONTENT.fertilizer}
          subText={ORIGINATION_DATA_CONTENT.required}
          addBtnLabel={ORIGINATION_DATA_CONTENT.add_fertilizer_btn_label}
          addNewBtnHandler={() =>
            setIsNitrogenModalOpen({
              flag: true,
              id: 0,
              data: { id: uniqueId(), year: year, formData: [] },
              currentRowId: 0,
            })
          }
          data={operationsData?.nitrogenList}
          disableAllFieldActions={disableAllFieldActions}
          ModalWrapper={NitrogenFertilizerWrapper}
          modalOpen={isNitrogenModalOpen}
          setModalOpen={setIsNitrogenModalOpen}
          submitHandler={submitPracticeHandler}
          makeFormDirty={makeFormDirty}
        />
        <ModalLessAccordionDisplayBox
          currentYear={year}
          fieldId={currentFieldId}
          mainLabel={ORIGINATION_DATA_CONTENT.tillage}
          subText={ORIGINATION_DATA_CONTENT.required}
          addBtnLabel={'+ Tillage'}
          addNewBtnHandler={() =>
            setIsTillageModalOpen({
              flag: true,
              id: 0,
              data: { id: uniqueId(), year: year, formData: [] },
              currentRowId: 0,
            })
          }
          data={operationsData?.tillageTypeDtoList}
          ModalWrapper={TillageModalWrapper}
          modalOpen={isTillageModalOpen}
          setModalOpen={setIsTillageModalOpen}
          submitHandler={submitPracticeHandler}
          tillageType={tillageType}
          makeFormDirty={makeFormDirty}
          disableAllFieldActions={disableAllFieldActions}
        />
        <ModalLessAccordionDisplayBox
          fieldId={currentFieldId}
          mainLabel={ORIGINATION_DATA_CONTENT.crop_section_title}
          jumpClassName={'crops-section'}
          subText={ORIGINATION_DATA_CONTENT.required_field}
          addBtnLabel={ORIGINATION_DATA_CONTENT.crop_button_label}
          addNewBtnHandler={() => {
            setIsAddCropModalOpen({
              flag: true,
              id: 0,
              data: { id: uniqueId(), year: year, formData: [] },
              currentRowId: 0,
            });
          }}
          data={operationsData?.cropList}
          ModalWrapper={AddCropModal}
          modalOpen={isAddCropModalOpen}
          setModalOpen={setIsAddCropModalOpen}
          disableAllFieldActions={disableAllFieldActions}
          submitHandler={submitPracticeHandler}
          currentYear={year}
          cropForm
          cropFormProps={{
            modalOpen: { flag: false, id: 0, data: null, currentRowId: 0 },
            setModalOpen: () => {},
            submitHandler: () => {},
            id: 0,
            setYearWiseCashCropList: () => {},
            yearWiseCashCropList: [],
            tabWiseSubmitHandler: () => {},
            totalFieldArea: 10,
            existingCropTypeIds: [],
          }}
          setIsDeleteModalOpen={setIsCropDeleteModalOpen}
          makeFormDirty={makeFormDirty}
          copyBtnLabel={ORIGINATION_DATA_CONTENT.copy_button_label}
          sampleId={sampleId}
          copyHandler={copyCrops}
        />
      </OperationsDataWrapper>
      <DialogBox
        dialogActions
        deleteOperation
        isOpen={isCropDeleteModalOpen.flag}
        onConfirm={() => handleCropDelete()}
        subtitle={ORIGINATION_DATA_CONTENT.delete_message
          .replace('{{year}}', year)
          .replace(
            ORIGINATION_DATA_CONTENT.crop_sub_section_title,
            checkTernaryCondition(
              isCropDeleteModalOpen.type === ORIGINATION_DATA_CONTENT.fallow,
              '',
              ORIGINATION_DATA_CONTENT.crop_sub_section_title,
            ),
          )
          .replace(
            '{{crop_type}}',
            whenValueIsUndefinedReturnEmptyString(isCropDeleteModalOpen.type),
          )}
        onCancel={() => {
          setIsCropDeleteModalOpen({ flag: false, id: null });
        }}
        declineCtnLabel={ORIGINATION_DATA_CONTENT.cancel_btn_label}
        acceptCtnLabel={ORIGINATION_DATA_CONTENT.delete_btn_label}
        subtitleColor={DARK_CHARCOAL}
        title={`Delete ${year} - ${checkTernaryCondition(
          isCropDeleteModalOpen.type === ORIGINATION_DATA_CONTENT.fallow,
          '',
          'Crop - ',
        )}  ${whenValueIsUndefinedReturnEmptyString(
          isCropDeleteModalOpen.type,
        )}`}
      />
      <DialogBox
        dialogActions
        deleteOperation
        isOpen={isDeleteModalOpen}
        onConfirm={() => {
          handleModalLessDelete();
          setIsDeleteModalOpen(false);
        }}
        subtitle={deleteData?.deleteModalContent}
        onCancel={() => {
          setIsDeleteModalOpen(false);
          setDeleteData(initialDeleteState);
        }}
        declineCtnLabel={ORIGINATION_DATA_CONTENT.cancel_btn_label}
        acceptCtnLabel={ORIGINATION_DATA_CONTENT.delete_btn_label}
        subtitleColor={DARK_CHARCOAL}
        isInsideBaseline
        title={deleteData?.deleteModalHeading}></DialogBox>

      <DialogBox
        buttonSX={{ fontWeight: 400 }}
        dialogActions
        deleteOperation
        isOpen={isReplacePromptOpen}
        onConfirm={() => {
          acceptReplace(replacePromptItems[replacePromptIndex]?.data);
        }}
        subtitle={`${PAGE_CONTENT.replaceModalContentPrefix} ${replacePromptItems[replacePromptIndex]?.titlePart}${PAGE_CONTENT.replaceModalContentSuffix}`}
        onCancel={declineReplace}
        declineCtnLabel="Cancel"
        acceptCtnLabel="Replace"
        isInsideBaseline
        title={`${PAGE_CONTENT.copy} ${replacePromptItems[replacePromptIndex]?.titlePart}`}></DialogBox>
    </div>
  );
};

DetailedOperationsDataComp.propTypes = {
  year: Proptypes.number,
  sampleId: Proptypes.number,
  setFieldDetails: Proptypes.func,
  setEnrollmentYears: Proptypes.func,
  currentFarmId: Proptypes.number,
  currentFieldId: Proptypes.number,
  makeFormDirty: Proptypes.func,
  disableAllFieldActions: Proptypes.bool,
};

export default DetailedOperationsDataComp;
