import styled from 'styled-components';
import {
  COOL_GRAY,
  DARK_CHARCOAL,
  GLOBAL_BLUE,
  QUILL_GRAY,
  RED,
  WHITE,
} from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';
import CloseIcon from '@mui/icons-material/Close';

export const SmallUploadWrapper = styled.div`
  width: ${({ width }) => checkTernaryCondition(width, width, '34.5rem')};
  background: #fff;
  cursor: ${({ isDisabled }) =>
    checkTernaryCondition(isDisabled, 'text', 'pointer')};
  padding: 0.5rem;
  border-radius: 2px;
  /* dashed border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%50' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%23333' stroke-width='1' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
`;
export const DragAndDropTitle = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: ${({ fontSize }) => fontSize || '0.875rem'};
  color: ${DARK_CHARCOAL};
  line-height: 19.6px;
  font-weight: 600;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
export const DragAndDropText = styled.div`
  color: ${COOL_GRAY};
  font-size: 0.75rem;
  line-height: 1.375rem;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  text-align: center;
  font-weight: 400;
`;
export const Subtext = styled.div`
  color: ${({ color }) => color || GLOBAL_BLUE};
  font-weight: 600;
  display: inline;
`;
export const UploadedContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ImgContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
`;

export const ImgWrapper = styled.div`
  position: relative;
  margin-bottom: 0.5rem;
  max-width: 198px;
  border: 1px solid #d0d0ce;
  border-radius: 2px;
`;

export const ImgTag = styled.img`
  display: block;
  width: inherit;
  height: 100%;
`;

export const deleteIconStyle = {
  position: 'absolute',
  fontSize: '1rem',
  top: 0,
  right: 0,
  cursor: 'pointer',
  color: WHITE,
  margin: '0.25rem 0.25rem 0 0',
};

export const ImgAndIconWrapper = styled.div`
  display: inline-flex;
  position: relative;
  border-radius: 2px;
  border-bottom: 1px solid ${QUILL_GRAY};
  border-color: ${({ hasError }) =>
    checkTernaryCondition(hasError, RED, QUILL_GRAY)};
  height: 100px;
  width: 198px;
  box-sizing: border-box;
`;

export const ChipsWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  flex-wrap: wrap;
`;

export const DropdownChip = styled.div`
  color: ${COOL_GRAY};
  font-size: 11px;
  border: 0.5px solid ${COOL_GRAY};
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 2.5px 4px;
  width: fit-content;
  box-sizing: border-box;
  height: 1.25rem;
`;

export const CloseIconStyled = styled(CloseIcon)`
  font-size: 1rem;
`;

export const UploadHelpText = styled.div`
  font-weight: 400;
  font-size: ${({ fontSize }) => fontSize || '0.688rem'};
  color: ${(props) => (props.iserror ? `#DA291C` : `#63666A`)};
  display: ${({ invisible }) =>
    checkTernaryCondition(invisible, 'none', 'block')};
  margin-top: ${({ marginTop }) =>
    checkTernaryCondition(marginTop, marginTop, '0.25rem')};
  line-height: 1.125rem;
  margin-bottom: ${({ marginBottom }) =>
    checkTernaryCondition(marginBottom, marginBottom, '0.25rem')};
`;

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 182px;
  margin: auto;
  padding: 0.5rem;
`;

export const FileUploadCompWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

export const TooltipHyperLink = styled.a`
  text-decoration: underline;
  color: ${GLOBAL_BLUE};
`;

export const ErrorTextWrapper = styled.div`
  color: ${RED};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 19.6px;
`;

export const DropDownStylesx = (length) => ({
  width: checkTernaryCondition(length > 20, '7.5rem', 'auto'),
  fontSize: '0.688rem',
  gap: '0.25rem',
});
