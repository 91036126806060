import React, { useState, useEffect } from 'react';
import TabPanel from './TabPanel.react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { TabBox, TabsWrapper, ScrollFixStyle } from './Tabber.style';
import AddButton from 'components/AddButton/AddButton.react';

const Tabber = ({
  currentTab,
  setCurrentTab,
  tabList,
  onTabChange,
  customTabberStyles,
  buttonName,
  disableButton,
  backgroundColor,
  textColor,
  customBtnSx,
  addBtnPadding,
  isModalPresent,
  hasMultipleModals,
  options,
  addButtonVisible = false,
  addButtonVisibleStyle,
  containerStyle,
  marginTop,
}) => {
  const handleTabChange = (_, tab) => {
    setCurrentTab(tab);
    onTabChange(tab);
  };

  const [style, setStyle] = useState({});

  const tabProps = (index) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `portfolio-page-tab-${index}`,
    };
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setStyle(ScrollFixStyle);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div style={{ position: 'relative', ...containerStyle }}>
      <TabsWrapper
        sx={customTabberStyles}
        value={currentTab}
        onChange={handleTabChange}
        marginTop={marginTop}
        aria-label="page-tabs">
        {tabList.map((tab, index) => (
          <TabBox
            firstTab={index === 0}
            className={classNames({ 'active-tab': currentTab === index })}
            key={tab.title}
            label={tab.title}
            {...tabProps(index)}
          />
        ))}
        <div style={addButtonVisibleStyle}>
          {addButtonVisible && (
            <AddButton
              buttonName={buttonName}
              disableButton={disableButton}
              backgroundColor={backgroundColor}
              textColor={textColor}
              customBtnSx={customBtnSx}
              addBtnPadding={addBtnPadding}
              isModalPresent={isModalPresent}
              hasMultipleModals={hasMultipleModals}
              options={options}
            />
          )}
        </div>
      </TabsWrapper>
      {tabList.map((tab, index) => (
        <TabPanel
          key={tab.title}
          value={currentTab}
          index={index}
          style={style}
          customType="table">
          {tab.component}
        </TabPanel>
      ))}
    </div>
  );
};

Tabber.propTypes = {
  currentTab: PropTypes.number.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  tabList: PropTypes.array.isRequired,
  onTabChange: PropTypes.func,
  customTabberStyles: PropTypes.object,
  buttonName: PropTypes.string,
  disableButton: PropTypes.bool,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  customBtnSx: PropTypes.object,
  addBtnPadding: PropTypes.string,
  isModalPresent: PropTypes.bool,
  hasMultipleModals: PropTypes.bool,
  options: PropTypes.object,
  addButtonVisible: PropTypes.bool,
  addButtonVisibleStyle: PropTypes.object,
  containerStyle: PropTypes.object,
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Tabber.defaultProps = {
  onTabChange: () => {},
  containerStyle: {},
};

export default Tabber;
