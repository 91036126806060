export const PAGE_CONTENT = {
  modalTitle: 'Add fields',
  modalSubtitle: 'Add fields using one of the following methods:',
  fileUploadText: 'Upload any farm or field boundary shape files',
  drawBoundaryText: 'Draw boundary',
  drawBoundarySubText: 'Click on map to place pins along field boundary',
  dropPinText: 'Drop pin',
  dropPinSubText: 'Click on map to drop pin per field',
  dragAndDropText: 'Drag and drop shape file here',
  upload: 'Upload',
  fileSizeInfo: 'Max. file size: 6 MB',
  multipleFieldValidationError:
    'Fields Validations Failed with multiple errors',
};
