import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  CancelBtnStyle,
  DialogActionStyle,
  DialogStyle,
  DialogTitleStyle,
  SubmitBtnStyle,
  ModalDialogStyle,
  closeIconSx,
  DialogContentStyle,
} from '../Modals.style';
import {
  MODAL_CONTENT,
  initialTillageModalData,
  initialTillageModalErrors,
} from './TillageModal.content';
import { primaryButtonActionStyle } from './TillageModal.style';
import { checkTernaryCondition } from 'utils/helper';
import { flexSpaceBetween } from 'theme/GlobalStyles';
import { uniqueId } from 'utils/uniqueIdGenerator';
import {
  ErrorWrapper,
  primaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import { BASELINE_MODAL_CONTENT } from 'pages/ParticipantDataCollection/ReportingComponents/TabBodyComponent.content';
import { STYLE_CONSTANTS } from 'pages/ParticipantDataCollection/components/ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.content';
import TillageModalContent from './TillageModalContent/TillageModalContent.react';

const TillageModal = ({
  modalOpen,
  setModalOpen,
  submitHandler,
  hasTestDefaultDate,
  formValue,
  setFormValue,
  customFieldChangeHandler,
  tillageTypeDropdown,
  errorState,
  setErrorState,
  isSubmitDisabled,
  setIsSubmitDisabled,
  setIsSubmitClicked,
  openDatePickersIndex,
  setOpenDatePickersIndex,
  onHoverDateApplied,
  setOnHoverDateApplied,
  tillageDateError,
  setTillageDateError,
  duplicatedDate,
  setDuplicateDate,
  setHoveredPickerIndex,
  noDataOptionSelected,
  tillageDatePickerProps,
  setModalErrorState,
  updateTillageDate,
  datesAreNotFilledOrInvalid,
  selectedTillagePractice,
  hoveredPickerIndex,
  handleTillageDateDelete,
  actsAsModal,
  isCombinationUnique = true,
  tillageTypeDeepTillage,
  makeFormDirty = () => null,
  checkTillageDepthError,
  checkTillageValidationsError,
  checkTillageSurfaceError,
  disableAllFieldActions = false,
}) => {
  const modalCloseHandler = () => {
    setModalOpen({ flag: false, id: 0, data: null, currentRowId: 0 });
    setFormValue(initialTillageModalData);
    setIsSubmitDisabled(false);
    setIsSubmitClicked(false);
    setTillageDateError({ flag: false, indexArray: [] });
    setDuplicateDate({ flag: false, dupIndexArray: [] });
    setErrorState(initialTillageModalErrors);
  };

  //  datesAreNotFilled() || tillageDateError.flag || findRequiredArray()
  const handleSubmit = () => {
    setIsSubmitClicked(true);
    findRequiredArray();
    const currentErrorStates = {
      tillagePractice: formValue.tillagePractice === '',
      tillageDates: findRequiredArray(),
    };
    setErrorState(
      checkTernaryCondition(
        noDataOptionSelected,
        initialTillageModalErrors,
        currentErrorStates,
      ),
    );
    const formHasError = Object.values({
      ...currentErrorStates,
      tillageDates:
        currentErrorStates.tillageDates.flag ||
        datesAreNotFilledOrInvalid() ||
        tillageDateError.flag,
    }).includes(true);
    if (!formHasError || noDataOptionSelected) {
      submitHandler(
        formValue,
        checkTernaryCondition(modalOpen.id === 0, uniqueId(), modalOpen.id),
        BASELINE_MODAL_CONTENT.tillage_list,
      );
      modalCloseHandler();
      makeFormDirty();
    }
  };

  const findRequiredArray = () => {
    if (formValue.tillageDates.length > 0) {
      let count = 0;
      let indexArray = [];
      formValue.tillageDates.forEach((date, index) => {
        if (date === null) {
          count++;
          indexArray.push(index);
        }
      });
      if (count > 0) {
        setErrorState({
          ...errorState,
          tillageDates: { flag: true, indexes: indexArray },
        });
        return {
          flag: true,
          indexes: indexArray,
        };
      } else {
        setErrorState({
          ...errorState,
          tillageDates: { flag: false, indexes: indexArray },
        });
        return {
          flag: false,
          indexes: indexArray,
        };
      }
    }
  };
  useEffect(() => {
    if (modalOpen.flag && modalOpen.data !== null) {
      let modalData = modalOpen.data.formData?.filter(
        (data) => data.id === modalOpen.currentRowId,
      )[0];
      if (modalData?.tillageDates?.length === 0) {
        modalData = {
          ...modalData,
          tillageDates: [null],
        };
      }
      setFormValue(
        checkTernaryCondition(modalData, modalData, initialTillageModalData),
      );
    }
  }, [modalOpen]);

  return (
    <Dialog
      disableRestoreFocus
      open={modalOpen.flag}
      onClose={modalCloseHandler}
      sx={{ ...DialogStyle, ...ModalDialogStyle }}>
      <DialogTitle sx={{ ...DialogTitleStyle, ...flexSpaceBetween }}>
        <div>{MODAL_CONTENT.updated_heading}</div>
        <CloseIcon
          onClick={modalCloseHandler}
          sx={closeIconSx}
          data-testid="close-icon"
        />
      </DialogTitle>
      <DialogContent
        sx={checkTernaryCondition(
          selectedTillagePractice === MODAL_CONTENT.noTillageOption,
          {
            padding: STYLE_CONSTANTS.zero_rem,
            marginBottom: STYLE_CONSTANTS.one_rem,
            '&.MuiDialogContent-root': {
              overflowY: 'clip',
            },
          },
          DialogContentStyle,
        )}>
        {!isCombinationUnique && (
          <ErrorWrapper letterSpacing={STYLE_CONSTANTS.zero_rem}>
            {MODAL_CONTENT.unique_combination_error_heading}
          </ErrorWrapper>
        )}
        <TillageModalContent
          modalOpen={modalOpen}
          noDataOptionSelected={noDataOptionSelected}
          setIsSubmitClicked={setIsSubmitClicked}
          selectedTillagePractice={selectedTillagePractice}
          formValue={formValue}
          customFieldChangeHandler={customFieldChangeHandler}
          setDuplicateDate={setDuplicateDate}
          duplicatedDate={duplicatedDate}
          checkTillageDepthError={checkTillageDepthError}
          checkTillageValidationsError={checkTillageValidationsError}
          checkTillageSurfaceError={checkTillageSurfaceError}
          setOnHoverDateApplied={setOnHoverDateApplied}
          onHoverDateApplied={onHoverDateApplied}
          errorState={errorState}
          setModalErrorState={setModalErrorState}
          tillageTypeDropdown={tillageTypeDropdown}
          setHoveredPickerIndex={setHoveredPickerIndex}
          tillageTypeDeepTillage={tillageTypeDeepTillage}
          hasTestDefaultDate={hasTestDefaultDate}
          openDatePickersIndex={openDatePickersIndex}
          updateTillageDate={updateTillageDate}
          setOpenDatePickersIndex={setOpenDatePickersIndex}
          tillageDatePickerProps={tillageDatePickerProps}
          tillageDateError={tillageDateError}
          hoveredPickerIndex={hoveredPickerIndex}
          handleTillageDateDelete={handleTillageDateDelete}
          actsAsModal={actsAsModal}
          disableAllFieldActions={disableAllFieldActions}
          isCombinationUnique={isCombinationUnique}
        />
      </DialogContent>
      <DialogActions
        style={{ ...DialogActionStyle, ...primaryButtonActionStyle }}>
        <Button
          disableElevation
          sx={[CancelBtnStyle, tertiaryButtonStyle]}
          onClick={modalCloseHandler}>
          {MODAL_CONTENT.cancel_btn_label}
        </Button>
        <Button
          disableElevation
          sx={[SubmitBtnStyle, primaryButtonStyle('0.516rem 0.906rem')]}
          disabled={isSubmitDisabled}
          onClick={handleSubmit}>
          {MODAL_CONTENT.submit_btn_label}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TillageModal.propTypes = {
  modalOpen: PropTypes.shape({
    flag: PropTypes.bool,
    id: PropTypes.number,
    data: PropTypes.shape({
      formData: PropTypes.array,
    }),
    currentRowId: PropTypes.number,
  }),
  actsAsModal: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool,
  setIsSubmitDisabled: PropTypes.func,
  setModalOpen: PropTypes.func,
  submitHandler: PropTypes.func,
  tillageTypeDropdown: PropTypes.array,
  hasTestDefaultDate: PropTypes.bool,
  setIsSubmitClicked: PropTypes.func,
  formValue: PropTypes.object,
  setFormValue: PropTypes.func,
  customFieldChangeHandler: PropTypes.func,
  errorState: PropTypes.object,
  setErrorState: PropTypes.func,
  openDatePickersIndex: PropTypes.array,
  setOpenDatePickersIndex: PropTypes.func,
  onHoverDateApplied: PropTypes.object,
  setOnHoverDateApplied: PropTypes.func,
  tillageTypeDeepTillage: PropTypes.bool,
  tillageDateError: PropTypes.object,
  setTillageDateError: PropTypes.func,
  checkTillageDepthError: PropTypes.object,
  checkTillageValidationsError: PropTypes.object,
  checkTillageSurfaceError: PropTypes.object,
  duplicatedDate: PropTypes.object,
  setDuplicateDate: PropTypes.func,
  setHoveredPickerIndex: PropTypes.func,
  noDataOptionSelected: PropTypes.bool,
  tillageDatePickerProps: PropTypes.object,
  setModalErrorState: PropTypes.func,
  updateTillageDate: PropTypes.func,
  datesAreNotFilledOrInvalid: PropTypes.func,
  selectedTillagePractice: PropTypes.string,
  hoveredPickerIndex: PropTypes.number,
  handleTillageDateDelete: PropTypes.func,
  isCombinationUnique: PropTypes.bool,
  makeFormDirty: PropTypes.func,
  disableAllFieldActions: PropTypes.bool,
};

export default TillageModal;
